




import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import Vue from 'vue';

const _visitor = {
  visitorName: '',
  // visitorNameEn: '',
  sex: '',
  mobile: '',
  email: '',
  cardType: 1, // 1身份证 2护照 3港澳居民来往内地通行证 4台湾居民来往内地通行证
  visitorIdentity: '',
  post: '',
  userCertificates: '', // 证件照
  identityCardFront: '',
  identityCardBack: '',
  department: '', // 部门
  companyTypes: [], // 公司所属类型
  companyService: [], // 公司所属类型
  products: [], // 公司所属类型
};
export default Vue.extend({
  components: {},
  async asyncData(ctx) {
    // 301重定向-重定向到新url
    console.log('512-512重定向');
    let newUrl: any = `/ticket/${ctx.params.id}.html`;
    ctx.redirect('301', newUrl);
    throw new Error(`/ticket/${ctx.params.id}.html`);

    return {};
  },
  data(): any {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
  // head(): Record<string, any> {

  //   return {};
  // },
});
